import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

function Footer() {
  return (
    <div className="section-footer">
      <div className="container">
        <h2> Follow us</h2>

        <div className="social-icons display--flex">
          <a href="https://twitter.com/thephoodles">
            {" "}
            <img
              alt={"Theif man"}
              src={"/config/images/twitter.svg"}
              target="_new"
            />
          </a>

          <a href="https://discord.gg/zpHtFZqyfV">
            {" "}
            <img
              alt={"Theif man"}
              src={"/config/images/discord.svg"}
              target="_new"
            />
          </a>

          <a href="https://opensea.io/collection/the-phoodles-official">
            <img
              alt={"Theif man"}
              src={"/config/images/opensea.svg"}
              target="_new"
            />{" "}
          </a>
        </div>

        <p> &copy; Copyright 2022 Phoodles. All rights reserved. </p>
      </div>
    </div>
  );
}

export default Footer;
