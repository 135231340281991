import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import About from "./About";
import Navigation from "./Navigation";
import Team from "./Team";
import Faq from "./Faq";
import Footer from "./Footer";





import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import Roadmap from "./Roadmap";

ReactDOM.render(
  <Provider store={store}>
    <Navigation />,
    <App />,
    <About/>,
    <Team/>,
    <Roadmap/>,
    <Faq/>,,
    <Footer/>

  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
