import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import { Tabs, TabNav, TabNavItem, TabContent, TabPanel } from 'react-smarttab'
import 'react-smarttab/dist/index.css'



function Roadmap() {
    return (
        <div className="section-roadmap container">


            <h3 className="half-half subtitle"> Follow the journey closely</h3>
            <h2 className="half-half header"> ROADMAP</h2>


        
        

            

                        <div class="timeline">
                            <ScrollAnimation animateIn="animate__fadeInUp">
                                <div class="container-timeline left">


                                    <div class="content">
                                        <h3> PHASE 1: STEALTH LAUNCH & GIVEBACK

                                        </h3>
                                        <p>
                                        4,444 Collection + Sneak Peeks and a  Phoodles give back! We will give back to the community and not over charge, the first 500 mints will be FREE.

                                        </p>



                                    </div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="animate__fadeInUp">
                                <div class="container-timeline right">
                                    <div class="content">
                                        <h3>  PHASE 2: POST LAUNCH
 </h3>


                                        <p> Our first donation to two organizations split equally, we will let the community be the voice and decide which organizations we will phoodle! 


                                        </p>
                                    </div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="animate__fadeInUp">
                                <div class="container-timeline left">
                                    <div class="content">
                                        <h3>  PHASE 3: COMMUNITY GROWTH

                                        </h3>
                                        <p>3 random Phoodlers are selected to be Phoodled. They will be given a free Doodle! We will hold contests and raffles/giveaways for the community and boost engagement and rewards!
</p>
                                    </div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="animate__fadeInUp">
                                <div class="container-timeline right">
                                    <div class="content">
                                        <h3> PHASE 4: SCALING

                                        </h3>
                                        <p>This is where we pivot but before that, 10 Phoodlers will be selected to receive free merch from the Phoodle Store. 
Donations, Project partnerships, marketing expansion and more giveaways! We will also announce roadmap V2 that will be tailored to the needs of the community.</p>
                                    </div>
                                </div>
                            </ScrollAnimation>

                      


                        </div>













                












        </div>
    )
}

export default Roadmap;
