import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";


function Team() {
    return (
        <div className="section-team ">

            <div className="container">
                <h3 className="half-half meeth3"> Meet the</h3>
                <h2 className="half-half"> Phoodles Team</h2>


                <div className="team_container">

                    <div>
                        <img
                            alt={"Theif man"}
                            src={"/config/images/phoodle2.png"}
                        />
                        <h3> Autumn</h3>
                    </div>

                    <div>
                        <img
                            alt={"Theif man"}
                            src={"/config/images/phoodle3.png"}
                        />
                        <h3> Kevin</h3>
                    </div>

                    <div>
                        <img
                            alt={"Theif man"}
                            src={"/config/images/phoodle4.png"}
                        />
                        <h3> Dracarys</h3>
                    </div>



                </div>
            </div>

        </div>
    )
}

export default Team;
