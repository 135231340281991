import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

function Navigation() {
  return (
    <div className="nav container">
      <ul className="nav-list">
        <li className="logo">
          {" "}
          <img src="/config/images/logotxt.png" />{" "}
        </li>

        <div className="navflexlogo">
          <li>
            {" "}
            <a
              className="twitterColor"
              href="https://twitter.com/thephoodles"
              target="_new"
            >
              {" "}
              TWITTER
            </a>
          </li>
          <li>
            {" "}
            <a href=" https://discord.gg/zpHtFZqyfV" target="_new">
              {" "}
              DISCORD
            </a>
          </li>
          <li>
            {" "}
            <a
              className="osColor"
              href="https://opensea.io/collection/the-phoodles-official"
              target="_new"
            >
              {" "}
              OPENSEA
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default Navigation;
