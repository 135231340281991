import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

function About() {
    return (
        <div className="section-about container">

            <ScrollAnimation animateIn="animate__fadeInLeft"  animateOnce={true}>

                <div className="features">

                    <div className=" half-half vert">
                        <h3> Featuring</h3>
                        <h2> The Phoodles</h2>
                        <p> Phoodles is a community driven collection of 4,444 randomly generated NFTs on the Ethereum network. Our goal is to create a one of a kind community appreciating the fusion of Phantabears and Doodles - the crowd favorites. We make this happen with a affordable and reasonable price of 0.04 ETH that gets you a Phoodle, that not only looks catchy on your profile picture, but gives you access to rewards, giveaways and makes you a part of great donations and acts of kindness. So are you a Phoodler?

</p>


                        <a href="https://discord.gg/zpHtFZqyfV" target="_new"> Join us </a>
                    </div>

                    <div className=" half-half aligncenter" >
                        <img
                            alt={"Theif man"}
                            src={"/config/images/phoodle1.png"}
                        />
                    </div>


                </div>

            </ScrollAnimation>


            {/* <ScrollAnimation animateIn="animate__fadeInRight"  animateOnce={true}>
                <div className="about">

                    <div className=" half-half vert">
                        <h3> About</h3>
                        <h2> It's time to fight the bad</h2>

                        <p>T: Trust - Trust is one of the most important factors for us to make this project succesfull!<br></br><br></br>
                            A: Work - Even if we complete our roadmap we won't stop working!<br></br><br></br>
                            C: Curiosity - Our team is working and learning all day, we want to make TAC the best it can be.</p>

                    </div>

                    <div className=" half-half" >
                        <img
                            alt={"Theif man"}
                            src={"/config/images/theif.png"}
                        />
                    </div>

                </div>

            </ScrollAnimation> */}
            {/* <div className="whitelist">
                <div className=" half-half vert">
                    <h3> features</h3>
                    <h2> Whitelist </h2>
                    <p> What for?

                        These are the main benefits of being whitelisted:<br></br><br></br>

                        - The Presale will be at a lower price rewarding the first members in the Project! (Smart)<br></br><br></br>

                        - You will be able to mint with no pressure because you reserved a spot. (Calm)<br></br><br></br>

                        - Lower gas-fees and bypassing the gas-wars at the public sale. (Economic)<br></br><br></br>


                    </p>

                    <p><strong>The requirements: </strong>

                        - Invite 15 people to this discord<br></br><br></br>

                        - Follow our all our social media in 「🔗」links <br></br><br></br>

                        - Must have "THIEF APE |" in your Discord name and one of our 「👀」sneak-peaks on your Avatar</p>



                </div>

            </div> */}








        </div>
    )
}

export default About;
