import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


export default function Faq() {
    return (

        <div className="section-faq">
            <div className="container">

                <h2 className="subtitle blacktxt"> Questions need Answers</h2>
                <h3 className="header blacktxt"> FAQ </h3>


                <Accordion allowZeroExpanded>
                    <AccordionItem


                    >
                        <AccordionItemHeading className="acc-heading">
                            <AccordionItemButton className="acb1">
                                
                                How can I buy a Phoodle?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                - You will be able to mint a Phoodle on our website on the Mint Day or on Opensea after mint! Join our discord for more information!

                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading className="acc-heading">
                            <AccordionItemButton className="acb2">
                                What does "mint" means?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                - NFT Minting is the process by which your digital art or digital content becomes a part of the Ethereum Blockchain.

                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>


                    <AccordionItem>
                        <AccordionItemHeading className="acc-heading">
                            <AccordionItemButton className="acb3">
                                How much does it cost to buy one of your nft?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                -  0.04 eth

                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>


                    <AccordionItem>
                        <AccordionItemHeading className="acc-heading">
                            <AccordionItemButton className="acb1">
                                Do you have a whitelist?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Nope, no whitelists! Minting will be open to everyone!


                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>




                    <AccordionItem>
                        <AccordionItemHeading className="acc-heading">
                            <AccordionItemButton className="acb2">
                                Where can I find The Phoodles on Opensea?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                You can find our Collection on <a href="#"> Opensea</a> here!
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>



                  


                    <AccordionItem>
                        <AccordionItemHeading className="acc-heading">
                            <AccordionItemButton className="acb1">
                            When is the mint? 
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            -The exact minting date will be announced on our discord and twitter! The first 500 mints will be completely FREE. 
So watch out for the Phoodles!
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>


                    <AccordionItem>
                        <AccordionItemHeading className="acc-heading">
                            <AccordionItemButton className="acb2" >
                            Do you have a rarity system?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            -Yes, we do! You will be able to check the rarity of your NFTs on Rarity.tools and several other sites after our mint! 

                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>






                </Accordion>

            </div> </div >
    );
}